import React, { Component } from "react";
import {
  FiTrendingUp,
  FiSearch,
  FiSmartphone,
  FiMonitor,
  FiUsers,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiMonitor />,
    title: "Website Development",
    description: "Single page applications using latest technologies available",
  },
  {
    icon: <FiSmartphone />,
    title: "Mobile App Development",
    description: "Cross platform applications for Android and IOS",
  },
  {
    icon: <FiUsers />,
    title: "Social media marketing,",
    description: "Build brands, increase sales, and drive website traffic",
  },
  {
    icon: <FiSearch />,
    title: "Search engine optimization",
    description: "Rank higher in search engines to receive more traffic",
  },
  {
    icon: <FiTrendingUp />,
    title: "Search Engine Marketing ",
    description: "Increase visibility in search engine primarily through ads",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <div  >
        <div className="row service-main-wrapper">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <div className="service service__style--2 text-left bg-gray" style={{backgroundColor:"#0c0c0c"}}>
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default ServiceThree;
