import React, { useRef, useState } from "react";

// import * as Yup from "yup";
// import styled from "@emotion/styled";
import "./JobDetailsStyle.css";
import Header from "../../component/header/Header";
import PageHelmet from "../../component/common/Helmet";
import FooterTwo from "../../component/footer/FooterTwo";

import Upload from "./Upload";
import { sendEmail } from "../../utils/email";
import { useParams } from "react-router-dom";
import HeaderThree from "../../component/header/HeaderThree";

const emailTemplateID = "template_ozgjyfg";

// And now we can use these
const SignupForm = ({ jobtitle }) => {
  const form = useRef();

  const [cvUrl, setcvUrl] = useState(null);

  return (
    <>
      <div className="contact-form--1">
        <form ref={form} onSubmit={(e) => sendEmail(e, form, emailTemplateID)}>
          <div className="container">
            <div
              className="row pb--40"
              style={{ borderBottom: "1px solid #333" }}
            >
              <div className="col-lg-4 ">
                <h4>Personal information</h4>
                <p className="mb--30">Tell us something about you</p>
                <p></p>
              </div>
              <div className="col-lg-8">
                <div className="form-wrapper">
                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="username"
                      id="item01"
                      placeholder="Your full name *"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="text"
                      name="email"
                      id="item02"
                      placeholder="Your email adress *"
                    />
                  </label>

                  <label htmlFor="item3">
                    <input
                      type="tel"
                      name="phone"
                      id="item3"
                      placeholder="Your phone number *"
                    />
                  </label>

                  <input
                    type="hidden"
                    className="form-control"
                    name="cvlink"
                    defaultValue={cvUrl}
                  />
                  <input
                    type="hidden"
                    className="form-control"
                    name="jobtitle"
                    defaultValue={jobtitle}
                  />
                </div>
              </div>
            </div>

            {/* <div
              className="row pt--40 pb--40 "
              style={{ borderBottom: "1px solid #333" }}
            >
              <div className="col-lg-4  ">
                <h4>CV/Resume *</h4>
                <p className="mb--30">Upload your CV or resume file</p>
                {cvUrl ? <a href={cvUrl}>Uploaded Link</a> : null}
              </div>
              <div className="col-lg-8 ">
                <div
                  className="form-wrapper"
                  style={{
                    height: "100%",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Upload setcvUrl={setcvUrl} />
                  <p style={{ marginLeft: 20 }}>
                    We accept PDF, DOC, DOCX, JPG and PNG files.
                  </p>
                </div>
              </div>
            </div>
            <hr /> */}

            <div
              className="row pt--40 pb--40 "
              style={{ borderBottom: "1px solid #333" }}
            >
              <div className="col-lg-4 ">
                <h4>Cover letter</h4>
                <p className="mb--30">Insert your cover letter here</p>
                <p></p>
              </div>
              <div className="col-lg-8">
                <div className="form-wrapper">
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="coverletter"
                      placeholder="Your cover letter"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row pt--90 pb--40">
              {/* <div className="col-lg-4 "></div> */}
              <div className="col-lg-12" style={{ textAlign: "center" }}>
                <button
                  className="btn-default"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Submit application
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const JobDetails = () => {
  const { jobtitle } = useParams();

  return (
    <div className="active-dark bg_color--10">
      <PageHelmet pageTitle="Job details" />
      <HeaderThree route="careers" />

      <div
        className="rn-page-title-area pt--40 pb--120 bg_image bg_image--27"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-single-page-title text-center pt--180 pb--80">
                <h2 className="title theme-gradient">{jobtitle}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-area ptb--120 bg_color--5">
        <SignupForm jobtitle={jobtitle} />
      </div>

      <FooterTwo />
    </div>
  );
};

export default JobDetails;
