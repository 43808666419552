import React from "react";

import useDrivePicker from "react-google-drive-picker";

function Upload({ setcvUrl }) {
  const [openPicker, data, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "613828201328-6k1i6l9lsjtvvnocslouovsboogrf4ea.apps.googleusercontent.com",
      developerKey: "AIzaSyCm32iSea06ZqoCWf5sr9P2fmr7GsgkYNc",
      viewId: "DOCUMENTS",
      disableDefaultView: true,
      setIncludeFolders: true,
      setSelectFolderEnabled: true,
      // customViews: customView,
      token:
        "ya29.a0AX9GBdWRttqVGYwFT_9TrUQmm_RLoasz7XjyT5LOEDww9Q1leKNZaxSaUbIMAqAPy9YuQ5_Za72Z6uUf9Ip_zfAbrMcmaME9NsqUr7p7yIWMy2HrJu3Jkf3RcedLLZ-jIfz-qPVyxmSbMtKZ72a7OLiRrPtraCgYKAWISARESFQHUCsbCaLWKN56k4kmdvqjjv23aOQ0163",
      showUploadView: true,
      showUploadFolders: false,
      supportDrives: false,
      multiselect: true,
      // customScopes: ["https://www.googleapis.com/auth/drive.file"],
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        console.log(data);
      },
    });
  };

  return (
    <a
      className="btn-default btn-border size-md"
      style={{ minWidth: 140, textAlign: "center" }}
      onClick={handleOpenPicker}
    >
      Add file
    </a>
  );
}

export default Upload;
