import React, { Component } from "react";

import { FiX, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { NavHashLink } from "react-router-hash-link";
class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
  }
  menuTrigger() {
    const header = document.querySelector(".header-wrapper");
    if (header) {
      header.classList.toggle("menu-open");
    }
  }

  CLoseMenuTrigger() {
    const header = document.querySelector(".header-wrapper");
    if (header) {
      header.classList.remove("menu-open");
    }
  }

  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, route } = this.props;

    console.log("route", route);
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-dark.png"
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img
          src="/assets/images/logo/logo-symbol-light.png"
          alt="Digital Agency"
        />
      );
    } else {
      logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    }

    return (
      <header className="header-area formobile-menu header--fixed   ">
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/" aria-label="Go to home page">
                <img
                  className="logo-1"
                  src="/assets/images/logo/logo-light.png"
                  alt="Logo Images"
                />
                <img
                  className="logo-2"
                  src="/assets/images/logo/logo-light.png"
                  alt="Logo Images"
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                items={["service", "about", "portfolio", "contact"]}
                currentClassName="is-current"
                offset={-200}
              >
                {false ? (
                  <li onClick={this.CLoseMenuTrigger}>
                    <Link aria-label="go to monsters" to={{ pathname: "/" }}>
                      Home
                    </Link>
                  </li>
                ) : (
                  <>
                    <li onClick={this.CLoseMenuTrigger}>
                      <NavHashLink
                        to="/"
                        activeClassName="selected"
                        activeStyle={{ color: "red" }}
                        aria-label="navigate to our Home page"
                      >
                        Home
                      </NavHashLink>
                    </li>
                    <li onClick={this.CLoseMenuTrigger}>
                      <NavHashLink
                        to="/#service"
                        activeClassName="selected"
                        activeStyle={{ color: "red" }}
                        aria-label="navigate to our Service page"
                      >
                        Service
                      </NavHashLink>
                    </li>

                    <li onClick={this.CLoseMenuTrigger}>
                      <NavHashLink
                        to="/#about"
                        activeClassName="selected"
                        activeStyle={{ color: "red" }}
                        aria-label="navigate to our about page"
                      >
                        About
                      </NavHashLink>
                    </li>
                    <li onClick={this.CLoseMenuTrigger}>
                      <NavHashLink
                        to="/#portfolio"
                        activeClassName="selected"
                        activeStyle={{ color: "red" }}
                        aria-label="navigate to our peojects page"
                      >
                        Projects
                      </NavHashLink>
                    </li>
                    <li onClick={this.CLoseMenuTrigger}>
                      <NavHashLink
                        to="/#contact"
                        activeClassName="selected"
                        activeStyle={{ color: "red" }}
                        aria-label="navigate to contact us"
                      >
                        Projects
                      </NavHashLink>
                    </li>
                  </>
                )}

                <li>
                  <Link
                    aria-label="go to blogs"
                    to={{ pathname: "/blogs" }}
                    style={{
                      color: route === "blog" ? "var(--color-primary)" : "",
                    }}
                  >
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    style={{
                      color: route === "careers" ? "var(--color-primary)" : "",
                    }}
                    aria-label="go to careers"
                    to={{ pathname: "/careers" }}
                  >
                    Careers
                  </Link>
                </li>
              </Scrollspy>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
