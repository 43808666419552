import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import FooterTwo from "./FooterTwo";
const logoUrl = (
  <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />
);

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style-01 bg_color--6">
          {/* Start Call to Action Area  */}
          <div className="im-call-to-action-area ptb--70 im-separator">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                  <div className="inner">
                    <h2 className="text-white mb--0">
                      Enough Talk, Let's Build Something Together
                    </h2>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                  <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                    <a
                      className="btn-default btn-large btn-border btn-opacity"
                      href="mailto:hello@monstersgraphics.com"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Call to Action Area  */}

          {/* Start Footer Area  */}
          <FooterTwo />
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
