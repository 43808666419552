import React, { Component } from "react";
// import Slider from "react-slick";
// import { slickDot } from "../page-demo/script";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import Particles from "react-particles-js";
import { FiChevronUp, FiX, FiMenu, FiCheck } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import AboutThree from "../component/HomeLayout/homeOne/AboutThree";
import CounterOne from "../elements/counters/CounterOne";
import Slider from "react-slick";
import {
  portfolioSlick2,
  SlideList,
  PortfolioList2,
  particlesParams,
} from "../constant/staticData";

class HomeParticles extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <div className="active-dark bg_color--10">
        <Helmet pageTitle="Monster Graphics" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed   ">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/" aria-label="Go to home page">
                  <img
                    className="logo-1"
                    src="/assets/images/logo/logo-light.png"
                    alt="Logo Images"
                  />
                  <img
                    className="logo-2"
                    src="/assets/images/logo/logo-light.png"
                    alt="Logo Images"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={["home", "service", "about", "portfolio", "contact"]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li onClick={this.CLoseMenuTrigger}>
                    <a href="#home" aria-label="navigate to home page">
                      Home
                    </a>
                  </li>
                  <li onClick={this.CLoseMenuTrigger}>
                    <a href="#service" aria-label="navigate to services page">
                      Services
                    </a>
                  </li>
                  <li onClick={this.CLoseMenuTrigger}>
                    <a href="#about" aria-label="navigate to about us page">
                      About
                    </a>
                  </li>
                  <li onClick={this.CLoseMenuTrigger}>
                    <a
                      aria-label="navigate to our peojects page"
                      href="#portfolio"
                    >
                      Projects
                    </a>
                  </li>
                  <li onClick={this.CLoseMenuTrigger}>
                    <a aria-label="navigate to contact us" href="#contact">
                      Contact
                    </a>
                  </li>
                  <li>
                    <Link aria-label="go to blogs" to={{ pathname: "/blogs" }}>
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link
                      aria-label="go to careers"
                      to={{ pathname: "/careers" }}
                    >
                      Careers
                    </Link>
                  </li>
                </Scrollspy>
              </nav>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>

        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div
          className="slider-activation slider-creative-agency with-particles"
          id="home"
        >
          <div className="frame-layout__particles">
            <Particles params={particlesParams} />
          </div>
          <div
            className="im_modern_slider bg_image bg_image--27 "
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title">{value.title}</h1>
                        ) : (
                          // <h2
                          //   className="title"
                          //   style={{ width: "660px", color: "#fff" }}
                          // >
                          //   {value.title}
                          // </h2>
                          ""
                        )}
                        {value.description ? (
                          <p className="description_style-2">
                            {value.description}
                          </p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="btn-default btn-large"
                              href="#contact"
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}
        {/* Start Service Area  */}
        <div
          className="service-area creative-service-wrapper ptb--120 bg_color--1"
          id="service"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="section-title text-left mb--30">
                  <span className="subtitle">What we can do for you</span>
                  <h2 className="title">Our services at a glance</h2>
                  <p className="description"></p>
                  <ul className="list-style--1">
                    {[
                      `We use the latest technologies such as React, Angular, Java,
                        Swift, Python, Titanium Appcelerator, Ionic, Cordova, and Open
                        Source Solutions`,
                      `We use leading frameworks like
                        ASP.NET MVC, CakePHP, Laravel, Yii, Django, RoR, etc.`,
                      `We are skilled at developing Mobile Apps (Native and cross-platform) using Flutter, Xamarin and
                        ReactNative.`,
                    ].map((name, index) => {
                      return (
                        <li key={index} style={{ display: "inline-flex" }}>
                          <div>
                            <FiCheck size={22} />
                          </div>
                          <p>{name}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <AboutThree />
        </div>
        <div className="counterup-area ptb--120 bg-theme-gradient theme-text-white">
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End About Area */}

        {/* Start Portfolio Area */}
        <div
          className="portfolio-area pt--120 pb--50 bg_color--1"
          id="portfolio"
        >
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-left   mb_sm--0">
                    <span className="subtitle">Our project</span>
                    <h2 className="title">Some of our Recent Works</h2>
                    <p className="description">
                      Our project portfolio includes IT and Marketing solutions
                      for industries like Automobiles, Aviation, Health and
                      Fitness, Fin-tech, Pharmacy and Medicines, Real Estate,
                      Information Technology, Law, Travel and Hospitality,
                      Finance, Music, Computers and Hardware, Business and
                      Services, and Home Interiors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="wrapper portfolio-sacousel-inner mb--120">
            <div className="portfolio-slick-activation mt--30 mt_sm--30">
              <Slider {...portfolioSlick2}>
                {PortfolioList2.map((value, index) => (
                  <div className="im_portfolio" key={index}>
                    <div className="thumbnail_inner">
                      <div className="thumbnail">
                        <a href={value.link}>{value.image}</a>
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <div className="portfolio_heading">
                          <div className="category_list">
                            <a href={value.link}>{value.category}</a>
                          </div>
                          <h4 className="title">
                            <a href={value.link}>{value.title}</a>
                          </h4>
                        </div>
                        <div className="portfolio_hover">
                          <p style={{ height: 70 }}>{value.description}</p>
                        </div>
                      </div>
                    </div>
                    <a
                      href={value.link}
                      className="transparent_link"
                      to={value.link}
                    ></a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          <Contact />
        </div>

        <FooterTwo />

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
      </div>
    );
  }
}
export default HomeParticles;
