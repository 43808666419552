const BlogContent = [
  {
    images: "blog1.jpeg",
    title: "Survive a recession as a small company",
    category: "3 min read",
  },
//   {
//     images: "02",
//     title: "A big ticket gone to be an interesting look New York.",
//     category: "React App",
//   },
//   {
//     images: "03",
//     title: "Getting tickets to the big show have a closer look.",
//     category: "Photoshop",
//   },
//   {
//     images: "04",
//     title: " Getting tickets to the big show",
//     category: "Development",
//   },
//   {
//     images: "05",
//     title: "A big ticket gone to be an interesting ",
//     category: "Management",
//   },
//   {
//     images: "06",
//     title: "The Home of the Future Could Bebes",
//     category: "Design",
//   },
//   {
//     images: "07",
//     title: "The Home of the Future Could Bebes",
//     category: "Design",
//   },
];

export default BlogContent;
