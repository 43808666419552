// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

// Home layout

import HomeParticles from "./home/HomeParticles";
// Element Layout
// import Service from "./elements/Service";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import careers from "./elements/careers/Careers";
import JobDetails from "./elements/JobDetails/JobDetails";

import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={HomeParticles}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blogs`}
            component={Blog}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blogs/:blogName`}
            component={BlogDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/careers`}
            component={careers}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/jobDetails/:jobtitle`}
            component={JobDetails}
          />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
