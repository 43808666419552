import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
// import Footer from "../../component/footer/Footer";
import FooterTwo from "../../component/footer/FooterTwo";
import Breadcrumb from "../common/Breadcrumb";
import HeaderThree from "../../component/header/HeaderThree";

const ServiceList = [
  {
    title: "Marketing Specialist",
    description: `Experienced · 3 - 5 Yrs of Exp · Marketing/PR/Advertising · Media/Journalism/Publishing· B2B · 
      E-Marketing
       · 
      Marketing
      · business· campaigns.`,
    type: "Full Time",
  },
  {
    title: "Graphic designer",
    description: `Experienced · 1 - 2 Yrs of Exp · Creative/Design/Art · Adobe Photoshop · 
      Graphic Design
       · 
      graphic designer
      · Creative· Adobe illustrator· photoshop · 
      Graphic`,
    type: "Part time",
  },
  {
    title: "UI&UX Creative",
    description: `Entry Level · 1+ Yrs of Exp · Creative/Design/Art · IT/Software Development· Adobe XD · 
      UI/UX
      · Information Technology (IT)· Web· Computer Science· Design · 
      UX
       · 
      UI`,
    type: "Freelance",
  },
  {
    title: "DevOps Engineer",
    description:
      "Experienced · 2+ Yrs of Exp · IT/Software Development · Engineering . DevOps·Jenkins· Git· MongoDB· JSON· Computer Science· Software",
    type: "Full Time",
  },
];

class BlogDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div className="active-dark bg_color--10">
        <PageHelmet pageTitle="careers" />

        <HeaderThree route="careers" />

        {/* Start Breadcrump Area */}
        {/* <Breadcrumb title={"Service"} /> */}

        {/* JOIN US
CURRENT OPENINGS */}

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Joing the Monsters!</h2>
                  {/* <h4 className="title pt--40 " style={{ color: "#eee" }}>
                    We're seeking top-tier contributors.
                  </h4> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="service-area ptb--150 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <span className="subtitle">JOIN US</span>
                  <h3>CURRENT OPENINGS</h3>
                  <p>
                    Monsters works with the best engineering teams around the
                    world to help them ship world software, <br /> and we do
                    that while having fun.<b> Come join us! </b>
                  </p>
                </div>
              </div>
            </div>
            <div className="row service-main-wrapper pt--60">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                  key={i}
                >
                  <Link to={`jobDetails/${val.title}`}>
                    <div className="service service__style--2 text-left">
                      <div className="content">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 12,
                          }}
                        >
                          <h3
                            className="title"
                            style={{
                              marginBottom: 0,
                              marginRight: 12,
                            }}
                          >
                            {val.title}
                          </h3>
                          <div
                            style={{
                              display: "block",
                              backgroundColor: "#EBEDF0",
                              fontSize: 12,
                              fontWeight: 600,
                              color: "#001433",
                              borderRadius: 4,
                              padding: "2px 8px",
                            }}
                          >
                            {val.type}
                          </div>
                        </div>

                        <p style={{ fontSize: 12 }}>{val.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </div>
    );
  }
}
export default BlogDetails;
