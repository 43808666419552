import emailjs from "@emailjs/browser";

const emailServiceId = "service_4k2ukdj";
const publicKey = "KAU8JZU94VBRJ047X";

export const sendEmail = (e, form, TemplateID) => {
  e.preventDefault();
  console.log("form", form);
  emailjs.sendForm(emailServiceId, TemplateID, form.current, publicKey).then(
    (result) => {
      alert("we got your message, Thanks for reaching out!");
      console.log(result.text);
    },
    (error) => {
      alert("Please try again!");

      console.log(error.text);
    }
  );
};
