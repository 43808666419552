import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FiClock,
  FiUser,
  FiBookOpen,
  FiMessageCircle,
  FiHeart,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";

class BlogDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div>
        <PageHelmet pageTitle="Blog Details" />
        <HeaderThree route="blog" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Survive a recession <br /> as a small company
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      Dec 5, 2022
                    </li>
                    <li>
                      <FiUser />
                      Mahmoud Hanora
                    </li>
                    <li>
                      <FiBookOpen />3 min read
                    </li>
                    {/* <li>
                      <FiHeart />
                      Like
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      For most of the small businesses out there who have never
                      seen a recession before, The current times are very scary.
                      It’s already difficult to scale and maintain a business
                      when everything is normal.
                      <br />
                      <br />
                      If you are planning on starting a business or if you
                      already have a small one, and you are worried about what
                      is coming next. I suggest you keep reading. As a digital
                      marketing, and a software agency we have been working with
                      businesses from every continent, so read along.
                    </p>
                    <div className="thumbnail">
                      <img
                        src="/assets/images/blog/blog1.jpeg"
                        alt="Blog Images"
                      />
                    </div>

                    <h4 className="title mt--70">#1 Accept the worst</h4>

                    <p className="mt--40">
                      Just try to live the worst scenario in your head, accept
                      it and then you will have the superpower of having nothing
                      to lose. By doing that you will be able to calm down
                      yourself and your team and you will start to think
                      straight.
                    </p>
                    <blockquote
                      className="rn-blog-quote"
                      style={{ paddingBottom: "20px", paddingTop: "20px" }}
                    >
                      “If you have nothing to lose, it automatically means you
                      have everything to gain” Dale Carnegie
                    </blockquote>

                    <h4 className="title mt--70">#2 Find the opportunity</h4>

                    <p>
                      A real entrepreneur is in the business of solving
                      problems/being useful. If you have made it during happy
                      times you can still make it during a recession just need
                      to be more resilient.
                    </p>

                    <p>
                      Now that you know people are not going to be able to
                      afford and pay as they used to, you need to think of
                      better ways to cut your costs without compromising
                      quality, AKA try new service providers and experiment with
                      cheaper suppliers (what big companies do)
                    </p>

                    <p>
                      In a nutshell you can think of recession as an opportunity
                      to grow.
                    </p>

                    <h4 className="title mt--70">
                      #3 Talk budget & Find funding
                    </h4>

                    <p>
                      Have a conversation with your clients and find out ways in
                      which you can adjust your packages and offerings to best
                      suit the current situation of the market. Doing that early
                      during the recession can save your business. Most
                      businesses fail because they do not understand the market.
                    </p>

                    <h4 className="title mt--70">#4 Time to Adapt</h4>
                    <p>
                      During a recession you can still sell the products you
                      used to sell but you may need to target a different market
                      segment. People who can afford and pay for your services
                      and products are still out there. You just need to adjust
                      the way you address them.
                    </p>

                    <h4 className="title mt--70">#4 Keep enough cash</h4>

                    <p>
                      Recession is not the time to pay your debts or to expand
                      your company or to introduce new products or services to
                      the market. Doing that will render you irrelevant, but
                      don’t be cheap, you still need to spend money to optimize,
                      like big players in the market do. The key here is to
                      focus on what you do best and optimize it.
                    </p>

                    {/* <p>
                      Check out <b> monstersgraphics.com </b>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}

        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </div>
    );
  }
}
export default BlogDetails;
