import React from "react";

const BookReadyPro_img = (
  <img
    src="/assets/images/portfolio/interior/bookready.jpeg"
    alt="book ready pro website"
  />
);
const EYEdentify_image = (
  <img
    src="/assets/images/portfolio/interior/portfolio-12.jpg"
    alt="Eye dentify website"
  />
);

const anamehani_img = (
  <img
    src="/assets/images/portfolio/interior/mehani.jpeg"
    alt="ana mehani wenbsite"
  />
);

export const portfolioSlick2 = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const batota_img = (
  <img
    src="/assets/images/portfolio/interior/batota.jpeg"
    alt="batota website"
  />
);

const botatos_img = (
  <img
    src="/assets/images/portfolio/interior/botatos.jpeg"
    alt="botatos mobile application"
  />
);

const brandsnest_img = (
  <img
    src="/assets/images/portfolio/interior/brandsnest.jpeg"
    alt="brandsnest web application"
  />
);

const wipo_img = (
  <img
    src="/assets/images/portfolio/interior/wipo (1).png"
    alt="Wipo organization website application"
  />
);

export const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: [`Scare your technical `, <br />, `problems away`],
    description: [
      `Trust us with your great idea`,
      <br />,
      ` and let us build it in a simple way.`,
    ],
    buttonText: "Get free consultation",
    buttonLink: "/contact",
  },
];

export const PortfolioList2 = [
  {
    image: BookReadyPro_img,
    category: "",
    title: "BookReadyPro",
    link: "https://www.bookreadypro.com/",
    description: `
      Book Ready Pro assists accountants and bookkeepers for tax preparation`,
  },

  {
    image: batota_img,
    category: "",
    title: "EbnBatota",
    link: "",
    description:
      "Ebn-batota is a double sided platform where travel enthusiasts meet with agencies.",
  },
  {
    image: EYEdentify_image,
    category: "",
    title: "Eye Dentify",
    link: "https://extreme.stanford.edu/projects/eye-dentify/",
    description:
      "It's an innovative wearable device to track patients in high-volume surgical cataract campaigns in Ghana.",
  },
  {
    image: anamehani_img,
    category: "",
    title: "Ana Mehani",
    link: "https://www.anamehani.com/",
    description:
      "Provide a helpful service that connects you with verified home maintenance service providers",
  },
  {
    image: brandsnest_img,
    category: "",
    title: "The Brandsnest",
    link: "",
    description:
      "A marketplace for selling and shipping products from the US to Egypt.",
  },
  {
    image: wipo_img,
    category: "",
    title: "TMCA",
    link: "",
    description: "An efficient tool for trademarks analysis.",
  },
  {
    image: botatos_img,
    category: "",
    title: "Potatocam",
    link: "",
    description:
      "Our native android application enables portrait mode in mobiles with a single camera.",
  },
  // {
  //   image: Portfolio_image3,
  //   category: "Company",
  //   title: "PostMyProject",
  //   link: "",
  //   description:
  //     "Online website that saves customers and contractors time and money by offering an online bidding platform to improve the service bidding process. ",
  // },
  // {
  //   image: Portfolio_image3,
  //   category: "Company",
  //   title: "TaxRabbit",
  //   link: "",
  //   description:
  //     "TaxRabbit automates the process of submitting the VAT Tax reports to the Lebanese Ministry of finances website.",
  // },
  // {
  //   image: Portfolio_image3,
  //   category: "Company",
  //   title: "Calivita",
  //   link: "https://callvita.com/",
  //   description:
  //     "Callvita is offering a variety of products to provide a manifested connection between both any service provider and an End user.",
  // },

  // {
  //   image: Portfolio_image3,
  //   category: "Company",
  //   title: "Semantic SEGMENTATION FOR AERIAL VIEWS",
  //   link: "",
  //   description:
  //     "For solving the segmentation problem for objects in aerial views in the available iSAID dataset we represent a network.",
  // },
  // {
  //   image: Portfolio_image3,
  //   category: "Company",
  //   title: "Guidiza Chatbot",
  //   link: "",
  //   description:
  //     "Creating a customized tour guide chatbot, to help tourists find out more information.",
  // },
  // {
  //   image: Portfolio_image3,
  //   category: "Company",
  //   title: "MEMS BASED AIR QUALITY ANALYZER",
  //   link: "",
  //   description:
  //     "Determining  gas leakage in factories and monitoring work environment quality.",
  // },
];


export const particlesParams = {
  particles: {
    number: {
      value: 30,
    },
    size: {
      value: 2,
    },
    color: {
      value: "#81d4fa",
    },
  },
  size: {
    value: 9,
    random: true,
    anim: {
      enable: true,
      speed: 10,
      size_min: 0.9,
      sync: true,
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: "grab",
      },
    },
  },
}